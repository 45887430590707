// Helper functions for reading and writing data to and from files, including encoding
import { getItemsCsv } from 'utilities/item';
import { dateToString } from 'utilities/string';

export function loadTextFile(accept) {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = accept;

    input.onchange = () => {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsText(file);
    };

    input.click();
  });
}

export function saveFile(filePath, text) {
  const type = 'text/plain';
  const blob = new Blob([text], { type });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filePath;
  link.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 0);
}

export function saveItemsCsvFile(title, items, headers) {
  const date = dateToString(new Date());
  const fileName = `${title}_${date}.csv`;
  const csv = getItemsCsv(items, headers);

  saveFile(fileName, csv);
}
