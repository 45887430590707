import React from 'react';
import PropTypes from 'prop-types';
import { matchUrls } from '@makeably/creativex-design-system';
import {
  abilitiesProps,
  paramsProps,
  getMenu,
} from 'components/organisms/InternalNavigation';
import styles from './InternalHeader.module.css';

const propTypes = {
  abilities: abilitiesProps.isRequired,
  params: paramsProps.isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  title: undefined,
};

function findMatchingOption(options, currentUrl, params) {
  return options.reduce((obj, { label, urls }) => {
    const urlIndex = urls.findIndex((url) => matchUrls(url, currentUrl, params));

    if (urlIndex !== -1) {
      return {
        option: label,
        url: urlIndex > 0 ? urls[0] : null,
      };
    }

    return obj;
  }, null);
}

function findCurrentLocation(menu) {
  const currentUrl = window.location.pathname;
  const params = new URLSearchParams(window.location.search);

  return menu.reduce((obj, { label, options }) => {
    const match = findMatchingOption(options, currentUrl, params);

    if (match) {
      return {
        ...match,
        section: label,
      };
    }

    return obj;
  }, {});
}

function getOption(section, url) {
  if (url) {
    return <a className={styles.link} href={url}>{ section }</a>;
  }
  return section;
}

function getLabel(current, title) {
  const {
    option,
    section,
    url,
  } = current;

  return (
    <h5>
      <span>{ `${section} / ` }</span>
      <span>{ getOption(option, url) }</span>
      { title && (
        <span>{ ` / ${title}` }</span>
      ) }
    </h5>
  );
}

function InternalHeader({
  abilities,
  params,
  title,
}) {
  const menu = getMenu(abilities, params);
  const current = findCurrentLocation(menu);

  return (
    <header
      aria-level="1"
      className={styles.header}
      role="heading"
    >
      { getLabel(current, title) }
    </header>
  );
}

InternalHeader.propTypes = propTypes;
InternalHeader.defaultProps = defaultProps;

export default InternalHeader;
