import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import InternalHeader from 'components/atoms/InternalHeader';
import InternalNavigation, { propTypes as navPropTypes } from 'components/organisms/InternalNavigation';
import styles from './InternalPage.module.css';

const propTypes = {
  content: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  nav: PropTypes.shape(navPropTypes).isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  title: undefined,
};

function renderLoading() {
  // @note: webpack has all components on our system preloaded, so this should never be seen
  return <div>Loading...</div>;
}

function InternalPage({
  content,
  nav,
  path,
  title,
}) {
  const Content = React.lazy(() => import(`components/${path}`));

  return (
    <div className={styles.page}>
      <InternalNavigation {...nav} />
      <div className={styles.layout}>
        <div className={styles.header}>
          <InternalHeader
            abilities={nav.abilities}
            params={nav.params}
            title={title}
          />
        </div>
        <main className={styles.content}>
          <Suspense fallback={renderLoading()}>
            <Content {...content} />
          </Suspense>
        </main>
      </div>
    </div>
  );
}

InternalPage.propTypes = propTypes;
InternalPage.defaultProps = defaultProps;

export default InternalPage;
