import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Card,
  Search,
} from '@makeably/creativex-design-system';
import ActionButton from 'components/molecules/ActionButton';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import { getItemSortBy } from 'utilities/item';
import { destroy } from 'utilities/requests';
import { internalGoogleAdsIntegrationPath } from 'utilities/routes';
import { toDate } from 'utilities/string';
import {
  getPage,
  getParams,
} from 'utilities/url';
import styles from './GoogleAdsIntegrations.module.css';

const integrationProps = PropTypes.shape({
  adAccountConnections: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  lastUpdated: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

const propTypes = {
  integrations: PropTypes.arrayOf(integrationProps).isRequired,
};

const headers = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'adAccountConnections',
    label: 'Ad Account\nConnections',
  },
  {
    key: 'lastUpdated',
    label: 'Last Updated',
  },
  {
    key: 'delete',
    label: '',
    sortable: false,
  },
];

function renderDeleteButton(disabled, active, onClick) {
  return (
    <ActionButton
      active={active}
      disabled={disabled}
      label="Delete"
      variant="destructive"
      onClick={onClick}
    />
  );
}

function getItems(integrations, onDelete, deletingId) {
  return integrations.map(({
    adAccountConnections,
    email,
    id,
    lastUpdated,
    name,
  }) => ({
    adAccountConnections: { value: adAccountConnections },
    delete: {
      element: renderDeleteButton(adAccountConnections > 0, id === deletingId, () => onDelete(id)),
      value: id,
    },
    email: { value: email },
    id: { value: id },
    lastUpdated: {
      label: toDate(new Date(lastUpdated)),
      value: lastUpdated,
    },
    name: { value: name },
  }));
}

function GoogleAdsIntegrations({
  integrations,
}) {
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState('');
  const [searchedItems, setSearchedItems] = useState([]);
  const [sort, setSort] = useState({
    key: 'email',
    asc: true,
  });
  const params = getParams(window);
  const [sortedItems, setSortedItems] = useState([]);
  const [page, setPage] = useState(getPage(params));
  const [deletingId, setDeletingId] = useState(null);

  const handleDelete = async (id) => {
    setDeletingId(id);
    const { isError } = await destroy(internalGoogleAdsIntegrationPath(id));

    if (!isError) {
      addToast('Google Ads integration removed');
    }
    window.location.reload();
  };

  useEffect(() => {
    setItems(getItems(integrations, handleDelete, deletingId));
  }, [integrations, deletingId]);

  useEffect(() => {
    const lower = search.toLowerCase();
    const found = items.filter((item) => {
      const email = item.email.value?.toLowerCase();
      const name = item.name.value?.toLowerCase();

      return email.includes(lower) || name.includes(lower);
    });

    setSearchedItems(found);
  }, [items, search]);

  useEffect(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    setSortedItems(searchedItems.slice().sort(byKeyDir));
  }, [searchedItems, sort]);

  const emptyState = (
    <div className="t-empty u-flexRow u-justifyCenter">
      No data to display
    </div>
  );

  return (
    <Card>
      <div className={styles.controls}>
        <Search
          placeholder="Search by email or name"
          value={search}
          onChange={setSearch}
        />
        <AddNewButton
          label="Add New"
          url="/auth/ad_words"
        />
      </div>
      <ItemsTable
        className="x-scroll-shadows"
        emptyTableContent={emptyState}
        headers={headers}
        items={sortedItems}
        page={page}
        sort={sort}
        onPageChange={setPage}
        onSortChange={setSort}
      />
    </Card>
  );
}

GoogleAdsIntegrations.propTypes = propTypes;

export default GoogleAdsIntegrations;
