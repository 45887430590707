import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Button,
  Card,
  Dropdown,
  useLocalStorage,
} from '@makeably/creativex-design-system';
import ActionButton from 'components/molecules/ActionButton';
import FilterTags from 'components/molecules/FilterTags';
import ItemsFilter from 'components/molecules/ItemsFilter';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import { saveFilterQuery } from 'utilities/filtering';
import { getItemSortBy } from 'utilities/item';
import {
  filterItems,
  getValueOptions,
} from 'utilities/itemFilter';
import { destroy } from 'utilities/requests';
import {
  internalGoogleAdsAccountPath,
  internalGoogleAdsIntegrationsPath,
  newInternalGoogleAdsAccountPath,
} from 'utilities/routes';
import { toDate } from 'utilities/string';
import {
  getPage,
  getParams,
  redirectWithParam,
  setParam,
} from 'utilities/url';
import styles from './GoogleAdsAccounts.module.css';

const accountProps = PropTypes.shape({
  apiClientId: PropTypes.string.isRequired,
  canDelete: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  integrationEmail: PropTypes.string.isRequired,
  lastUpdated: PropTypes.string.isRequired,
  brand: PropTypes.string,
  market: PropTypes.string,
});

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
});

const filterProps = PropTypes.objectOf(
  PropTypes.arrayOf(optionProps),
);

const propTypes = {
  adAccounts: PropTypes.arrayOf(accountProps).isRequired,
  companyOptions: PropTypes.arrayOf(optionProps).isRequired,
  initialFilterSelections: filterProps,
  selectedCompanyOption: optionProps,
};

const defaultProps = {
  initialFilterSelections: {},
  selectedCompanyOption: undefined,
};

const headers = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'apiClientId',
    label: 'API Client ID',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'integrationEmail',
    label: 'Integration Email',
  },
  {
    key: 'lastUpdated',
    label: 'Last Updated',
  },
  {
    key: 'delete',
    label: '',
    sortable: false,
  },
];

const filterDimensions = [
  {
    value: 'brand',
    label: 'Brand',
  },
  {
    value: 'market',
    label: 'Market',
  },
  {
    value: 'integrationEmail',
    label: 'Integration Email',
  },
];

function renderDeleteButton(disabled, active, onClick) {
  return (
    <ActionButton
      active={active}
      disabled={disabled}
      label="Delete"
      variant="destructive"
      onClick={onClick}
    />
  );
}

function getOptionalDimension(value) {
  if (!value) {
    return {
      value: '~',
      label: 'N/A',
    };
  }
  return { value };
}

function getItems(adAccounts, onDelete, deletingId) {
  return adAccounts.map(({
    apiClientId,
    integrationEmail,
    brand,
    canDelete,
    id,
    market,
    lastUpdated,
  }) => ({
    apiClientId: { value: apiClientId },
    integrationEmail: { value: integrationEmail },
    brand: getOptionalDimension(brand),
    delete: {
      element: renderDeleteButton(!canDelete, id === deletingId, () => onDelete(id)),
      value: id,
    },
    id: { value: id },
    market: getOptionalDimension(market),
    lastUpdated: {
      label: toDate(new Date(lastUpdated)),
      value: lastUpdated,
    },
  }));
}

function GoogleAdsAccounts({
  adAccounts,
  companyOptions,
  initialFilterSelections,
  selectedCompanyOption,
}) {
  const params = getParams(window);
  const [lastCompanyId, setLastCompanyId] = useLocalStorage('cxIntCompanyId', null);
  const [selectedCompany, setSelectedCompany] = useState(selectedCompanyOption);
  const [items, setItems] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterSelections, setFilterSelections] = useState(initialFilterSelections);
  const [filteredItems, setFilteredItems] = useState(items);
  const [sort, setSort] = useState({
    key: 'lastUpdated',
    asc: false,
  });
  const [sortedItems, setSortedItems] = useState([]);
  const [page, setPage] = useState(getPage(params));
  const [deletingId, setDeletingId] = useState(null);

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
    setLastCompanyId(option?.value);
    redirectWithParam('company_id', option.value, params, window);
  };

  const handleDelete = async (id) => {
    setDeletingId(id);
    const { isError } = await destroy(internalGoogleAdsAccountPath(id));

    if (!isError) {
      addToast('Google Ads account removed');
    }
    window.location.reload();
  };

  useEffect(() => {
    if (!selectedCompanyOption?.value && lastCompanyId) {
      const found = companyOptions.find((option) => option.value === lastCompanyId);

      if (found) {
        handleCompanyChange(found);
      }
    }
  }, [selectedCompanyOption, companyOptions, lastCompanyId]);

  useEffect(() => {
    const allItems = getItems(adAccounts, handleDelete, deletingId);
    const options = getValueOptions(filterDimensions, allItems);

    setItems(allItems);
    setFilterOptions(options);
  }, [adAccounts, deletingId]);

  useEffect(() => {
    setFilteredItems(filterItems(items, filterSelections));
  }, [items, filterSelections]);

  useEffect(() => {
    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    setSortedItems(filteredItems.slice().sort(byKeyDir));
  }, [filteredItems, sort]);

  const handleFilterSelect = async (value) => {
    setPage(1);
    setFilterSelections(value);

    if (Object.keys(value).length > 0) {
      const uuid = await saveFilterQuery(value);
      setParam('filter_uuid', uuid, params, window);
    } else {
      setParam('filter_uuid', '', params, window);
    }
  };

  const emptyState = (
    <div className="t-empty u-flexRow u-justifyCenter">
      No data to display
    </div>
  );

  return (
    <Card>
      <div className={styles.links}>
        <Button
          label="Integration Emails"
          url={internalGoogleAdsIntegrationsPath()}
          variant="tertiary"
        />
        <AddNewButton
          label="Connect Accounts"
          url={newInternalGoogleAdsAccountPath({ company_id: selectedCompany?.value })}
        />
      </div>
      <div>
        <div className={styles.controls}>
          <Dropdown
            label="Company"
            menuProps={{ size: 'medium' }}
            options={companyOptions}
            selected={selectedCompany}
            size="medium"
            onChange={handleCompanyChange}
          />
          <div className={styles.filter}>
            <ItemsFilter
              dimensions={filterDimensions}
              isOpen={filterOpen}
              options={filterOptions}
              selections={filterSelections}
              onClose={() => setFilterOpen(false)}
              onOpen={() => setFilterOpen(true)}
              onSelect={handleFilterSelect}
            />
            <FilterTags
              dimensions={filterDimensions}
              selections={filterSelections}
              onClick={() => setFilterOpen(true)}
              onRemove={handleFilterSelect}
            />
          </div>
        </div>
      </div>
      <ItemsTable
        className="x-scroll-shadows"
        emptyTableContent={emptyState}
        headers={headers}
        items={sortedItems}
        page={page}
        sort={sort}
        onPageChange={setPage}
        onSortChange={setSort}
      />
    </Card>
  );
}

GoogleAdsAccounts.propTypes = propTypes;
GoogleAdsAccounts.defaultProps = defaultProps;

export default GoogleAdsAccounts;
